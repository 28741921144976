@charset "utf-8";

.infographics{
  font-size: 18px;
  &--sp {
    display: none;
  }
  &__inner {
    width: $inner-width;
    margin: auto;
  }
  &__layout-area1{
    display: flex;
    &__wrap1{
      display: flex;
      flex-wrap:wrap;
      &__box1{
        &:not(:first-child){
        margin: 0 0 0 30px;
        }
        width: 300px;
      }
      &__box2{
        margin: 35px 0 0 0;
        width: 630px;
      }
    }
    &__wrap2__box1{
      width: 300px;
    }
  }
  &__layout-area2{
    display: flex;
    margin: 30px 0 30px 0;
    &__box1{
      &:not(:first-child){
        margin: 0 0 0 30px;
      }
      width: 300px;
    }
  }
  &__layout-area3{
    display: flex;
    margin: 30px 0 30px 0;
    &__box1{
      width: 300px;
    }
    &__box2{
      margin: 0 0 0 30px;
      width: 630px;
    }
  }
  &__layout-area4{
    display: flex;
    margin: 30px 0 30px 0;
    &__box1{
      &:not(:first-child){
        margin: 0 0 0 30px;
      }
      width: 300px;
    }
  }
  &__layout-area5{
    display: flex;
    margin: 30px 0 30px 0;
    &__box1{
      width: 630px;
    }
    &__box2{
      margin: 0 0 0 30px;
      width: 300px;
    }
  }
  &__layout-area6{
    display: flex;
    margin: 30px 0 190px 0;
    &__box1{
      width: 300px;
    }
    &__box2{
      margin: 0 0 0 30px;
      width: 630px;
    }
  }
}

@include mq-sp {
  .infographics {
    display: none;
    &--sp {
      display: block;
      margin-bottom: 60px;
    }
    &__inner {
      width: auto;
      padding: 0 20px;
    }
    &__layout-area {
      display: flex;
      justify-content: space-between;
      &:not(:first-child) {
        margin-top: 15px;
      }
      &__box {
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
}