@charset "utf-8";

@mixin mq-sp {
  @media (max-width: ($breakpoint)) {
    @content;
  }
}

@mixin mq-pc {
  @media (max-width: (1280px)) {
    @content;
  }
}