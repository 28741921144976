@charset "utf-8";

.about-nav{
  font-size: 18px;
  &__inner{
    width: $inner-width;
    margin: auto;
  }
  &__list {
    display: flex;
    margin: 150px 0 100px 0;
    &__item {
      flex: 1;
      &:not(:first-child){
        margin: 0 0 0 50px;
      }
    }
    &__box{
      flex: 1;
      display: block;
      &:not(:first-child){
        margin: 0 0 0 50px;
      }
      &__img{
        margin: 0 0 10px 0;
      }
      &__title{
        display: block;
        width: 100%;
        padding: 15px 0 12px;
        margin: 0 0 10px 0;
        background: #392f2c;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #fff;
        &.is-current{
          background: $accent-color;  
        }
      }
      &__caption{
        text-align: center;
        font-weight: bold;
      }
    }
  }
}

@include mq-sp {
  .about-nav {
    display: none;
    &__inner {
      width: auto;
    }
    &__list {
      display: block;
      margin: 80px 0 60px;
      padding: 0 20px;
      &__item {
        &:not(:first-child){
          margin: 40px 0 0;
        }
      }
    }
  }
}