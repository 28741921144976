@charset "utf-8";

.recruit-link {
  position: relative;
  padding: 120px 0;
  background: #e6e6e6;
  &__title {
    position: absolute;
    top: 0;
    left: 12%;
    &__image {
      width: (280px / 2);
      height: (86px / 2);
    }
  }
  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    flex: 1;
    padding: 0 25px 0 0;
    text-align: right;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.8;
    &__anchor {
      display: inline-block;
      margin: 30px 0 0;
      padding: 3px 16px;
      background: #fff;
      color: $accent-color;
      font-size: 16px;
    }
  }
  &__image {
    width: 720px;
  }
}

@include mq-sp {
  .recruit-link {
    padding-top: 40px;
    padding-bottom: 28px;
    &__title {
      left: 5%;
      z-index: 100;
      &__image {
        width: (280px / 3.5);
        height: (86px / 3.5);
      }      
    }
    &__box {
      flex-direction: column;
      align-items: flex-end;
    }
    &__text {
      padding: 0 14px 0 0;
      font-size: 19px;
      &__anchor {
        margin-top: 12px;
        padding: 3px 10px;
        font-size: 14px;
      }
    }
    &__image {
      width: 95%;
      margin-top: 24px;
    }
  }
}