@charset "utf-8";

.pagetop {
  padding: 20px 25px 10px 0;
  text-align: right;
  &__anchor {
    display: inline-block;
    font-size: 14px;
    &::after {
      content: '';
      display: inline-block;
      width: (34px / 2);
      height: (46px / 2);
      background: url(../img/pagetop.png);
      background-size: cover;
      vertical-align: bottom;
    }
  }
}

@include mq-sp {
  .pagetop {
    padding-right: 20px;
  }
}