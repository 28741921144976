@charset "utf-8";

.nav {
  position: fixed;
  top: 220px;
  right: 0;
  width: 125px;
  z-index: 1;
  &__list {
    padding: 30px 20px;
    background: rgba($color: #fff, $alpha: .6);
    &--home {
      padding: 30px 20px;
      background: rgba($color: #231915, $alpha: .4);
      a {
        color: #fff;
        &:hover {
          color: $accent-color;
        }
      }
    }
    &__item {
      &:not(:first-child) {
        margin: 30px 0 0;
      }
    }
    &__anchor {
      padding: 0 0 0 12px;
      font-size: 14px;
      &.is-current {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          width: 8px;
          height: 8px;
          margin-top: -5px;
          background: $accent-color;
          border-radius: 999em;
        }
      }
    }
  }
  &__menu-button {
    display: none;
  }
}

@include mq-pc {
  .nav {
    left: 1135px;
    right: auto;
  }
}

@include mq-sp {
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    right: auto;
    width: 100%;
    background: rgba($color: #13100d, $alpha: .8);
    text-align: center;
    z-index: 150;
    &__list {
      display: none;
      padding: 64px 0 0;
      background: none;
      &--home {
        display: none;
        padding: 64px 0 0;
        background: none;
      }
      &__item {
        &:not(:first-child) {
          margin: 0;
          border-top: 1px #7d7b79 solid;
        }
      }
      &__anchor {
        display: block;
        padding: 18px;
        color: #fff;
        font-size: 18px;
        &.is-current {
          &::before {
            display: none;
          }
        } 
      }
    }
    &__menu-button {
      position: absolute;
      top: 25px;
      left: 25px;
      display: block;
      padding: 0 0 0 12px;
      color: #fff;
      font-size: 18px;
      z-index: 200;
      &:hover {
        color: #fff;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 10px;
        height: 10px;
        margin-top: -6px;
        background: $accent-color;
        border-radius: 999em;
      }
    }
  }
}