@charset "utf-8";

.pagetitle {
  width: $inner-width;
  margin: auto;
  padding: 120px 0 140px;
  &__image {
    &--about {
      width: (458px / 2);
      height: (244px / 2);
      background: url(../img/pagetitle__image--about.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
    &--vision {
      width: (558px / 2);
      height: (368px / 2);
      background: url(../img/pagetitle__image--vision.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
    &--location {
      width: (750px / 2);
      height: (368px / 2);
      background: url(../img/pagetitle__image--location.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
    &--infographics {
      width: (1124px / 2);
      height: (368px / 2);
      background: url(../img/pagetitle__image--infographics.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
    &--access {
      width: (552px / 2);
      height: (242px / 2);
      background: url(../img/pagetitle__image--access.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
    &--recruit {
      width: (646px / 2);
      height: (244px / 2);
      background: url(../img/pagetitle__image--recruit.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
    &--contact {
      width: (646px / 2);
      height: (244px / 2);
      background: url(../img/pagetitle__image--contact.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
    &--privacypolicy {
      width: (918px / 2);
      height: (204px / 2);
      background: url(../img/pagetitle__image--privacypolicy.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
    &--disclaimers {
      width: (716px / 2);
      height: (202px / 2);
      background: url(../img/pagetitle__image--disclaimers.png) no-repeat;
      background-size: cover;
      text-indent: -999em;
    }
  }
}

@include mq-sp {
  .pagetitle {
    width: auto;
    padding: 35px 0 60px 20px;
    &__image {
      &--about {
        width: (672px / 3.5);
        height: (288px / 3.5);
        background-image: url(../img/pagetitle__image--about-sp.png);
      }
      &--vision {
        width: (724px / 3.5);
        height: (290px / 3.5);
        background-image: url(../img/pagetitle__image--vision-sp.png);
      }
      &--location {
        width: (726px / 3.5);
        height: (288px / 3.5);
        background-image: url(../img/pagetitle__image--location-sp.png);
      }
      &--infographics {
        width: (1096px / 4);
        height: (288px / 4);
        background-image: url(../img/pagetitle__image--infographics-sp.png);
      }
      &--access {
        width: (550px / 3.5);
        height: (286px / 3.5);
        background-image: url(../img/pagetitle__image--access-sp.png);
      }
      &--recruit {
        width: (634px / 3.5);
        height: (288px / 3.5);
        background-image: url(../img/pagetitle__image--recruit-sp.png);
      }
      &--contact {
        width: (646px / 3.5);
        height: (244px / 3.5);
      }
      &--privacypolicy {
        width: (918px / 3.5);
        height: (204px / 3.5);
      }
      &--disclaimers {
        width: (716px / 3.5);
        height: (202px / 3.5);
      }
    }
  }
}