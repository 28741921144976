@charset "utf-8";

.access{
  font-size: 18px;
  &__inner {
    width: $inner-width;
    margin: auto;
  }
  &__box{
    &:not(:first-child){
      padding-top: 50px;
      border-top: 1px #d2d2d2 solid;
    }
    &:not(:last-child){
      margin-bottom: 50px;
    }
    &:last-child{
      margin-bottom: 75px;
    }
    &__info{
      display: flex;
      margin:0 0 30px 0;
      &__img{
        width: 580px;
        &--nagoya{
          width: 270px;
        }
      }
      &__text{
        flex: 1;
        margin: 0 0 0 30px;
        &__caption{
          margin: 0 0 8px 0;
          font-size: 14px;
        }
        &__title{
          font-size: 30px;
          font-weight: bold;
        }
        &__incname{
          font-size: 18px;
          margin: 10px 0 0 0;
        }
        &__address{
          margin: 25px 0 0 0;
          line-height: 1.5;
          text-align: left;
        }
      }
    }
    &__map{
      height: 460px;
    }
  }
}

@include mq-sp {
  .access {
    margin-bottom: 60px;
    &__inner {
      width: auto;
      padding: 0 20px;
    }
    &__box {
      &__info {
        display: block;
        margin-bottom: 0;
        &__img {
          width: auto;
        }
        &__text {
          margin: 20px 0;
          &__caption {
            font-size: 14px;
          }
          &__title {
            font-size: 21px;
          }
          &__incname {
            margin-top: 20px;
          }
          &__address {
            margin-top: 20px;
            font-size: 14px;
          }
        }
        &__img {
          &--nagoya {
            width: 50%;
          }
        }
      }
      &__map {
        height: 90vw;
      }
    }
  }
}