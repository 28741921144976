@charset "utf-8";

.top-visual {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 640px;
  &__image {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background: #13100d;
    &--1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../img/top-visual-1.jpg) no-repeat;
      background-size: cover;
      z-index: 1;
    }
    &--2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../img/top-visual-2.jpg) no-repeat;
      background-size: cover;
    }
  }
  &__caption {
    position: absolute;
    top: 15px;
    left: 15px;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
    font-size: 14px;
    z-index: 50;
  }
  &__logo {
    position: absolute;
    top: 50%;
    left: 20px;
    margin: (-160px / 2) 0 0;
    z-index: 50;
    &__image {
      width: (1786px / 2);
      height: (160px / 2);
    }
  }
  &__nav {
    &--top {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 50;
      //display: none;
    }
    &--bottom {
      position: absolute;
      right: 15px;
      bottom: 15px;
      z-index: 50;
    }
    &__list {
      &__item {
        &--top {
          display: inline-block;
          &:not(:first-child) {
            a {
              &::before {
                content: ' | ';
                color: #fff;
              }
            }
          }
        }
        &--bottom {
          display: inline-block;
          &:not(:first-child) {
            a {
              margin-left: 32px;
            }
          }
        }
      }
      &__anchor {
        color: #fff;
        font-size: 13px;
        &.is-current {
          color: $accent-color;
        }
      }
    }
  }
  &__arrow {
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin: 0 0 0 (-120px / 4);
    z-index: 1;
    &__anchor {
      color: #fff;
      font-size: 12px;
      &::after {
        content: '';
        display: block;
        width: (120px / 2);
        height: (62px / 2);
        padding: 12px 0 0;
        background: url(../img/top-visual__arrow.png) 0 12px no-repeat;
        background-size: (120px / 2) (62px / 2);
      }
    }
  }
}

@include mq-sp {
  .top-visual {
    height: 90vh;
    min-height: 0;
    &__caption {
      top: 70px;
      left: 8px;
      font-size: 9px;
      line-height: 1.2;
    }
    &__logo {
      left: 5%;
      margin-top: -5%;
      &__image {
        width: 95%;
        height: auto;
      }
    }
    &__nav {
      &--top {
        position: fixed;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        padding: 24px 12px;
        background: #13100d;
        text-align: right;
        z-index: 150;
      }
      &--bottom {
        right: 8px;
        bottom: 8px;
      }
      &__list {
        //display: none;
        &__item {
          &--bottom {
            display: block;
            text-align: right;
            &:not(:first-child) {
              a {
                display: inline-block;
                margin-top: 4px;
                margin-left: 0;
              }
            }
          }
        }
        &__anchor {
          font-size: 12px;
        }
      }
    }
    &__arrow {
      bottom: 30px;
      &__anchor {
        font-size: 9px;
        &::after {
          width: (120px / 2.5);
          height: (62px / 2.5);
          padding-top: 6px;
          background-position-y: 6px; 
          background-size: (120px / 2.5) (62px / 2.5);
        }
      }
    }
  }
}