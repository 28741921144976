@charset "utf-8";

.access-link {
  position: relative;
  margin: 0 0 24px;
  &__title {
    position: absolute;
    top: 0;
    left: 12%;
    &__image {
      width: (280px / 2);
      height: (86px / 2);
    }
  }
  &__list {
    &__item {
      &:not(:first-child) {
        margin: 3px 0 0;
      }
    }
    &__anchor {
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 195px;
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
      &__box {
        display: block;
        width: 75%;
        color: #fff;
        font-size: 14px;
      }
      &__title {
        display: block;
        padding: 8px 0;
        font-weight: bold;
        font-size: 24px;
      }
      &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        transition: all 0.25s ease-out;
        z-index: -10;
      }
    }
  }
}

@include mq-sp {
  .access-link {
    margin-bottom: 16px;
    &__title {
      left: 5%;
      &__image {
        width: (280px / 3.5);
        height: (86px / 3.5);
      }
      z-index: 100;
    }
    &__list {
      &__anchor {
        height: 120px;
        &.is-sp {
          &--nagoya {
            background: url(../img/access-link__list__item--nagoya.jpg) no-repeat;
            background-size: cover;
          }
          &--famoso {
            background: url(../img/access-link__list__item--famoso.jpg) no-repeat;
            background-size: cover;
          }
          &--myanstar {
            background: url(../img/access-link__list__item--myanstar.jpg) no-repeat;
            background-size: cover;
          }
        }
        &__box {
          width: 90%;
          font-size: 11px;
        }
        &__title {
          padding-top: 4px;
          padding-bottom: 4px;
          font-size: 21px;
        }
        &__image {
          display: none;
        }
      }
    }
  }
}