@charset "utf-8";

.privacypolicy{
  line-height: 1.8;
  &__inner{
    width: $inner-width;
    margin: auto;
  }
  &__section{
    margin: 0 0 40px 0;
    &:last-child{
      margin: 0 0 100px 0;
    }
    &__title{
			margin-bottom: 15px;
      &--foreword{
        margin: 0 0 60px 0;
      }
    }
		&__lead{
			text-indent: 1em;
		}
    &__text{
			padding-left: 1em;
			text-indent: 1em;
      &--annotation {
				padding-left: 1em;
//        margin: 0 0 40px 0;
      }
    }
		&__list{
			padding-left: 1em;
			&__item{
				padding-left: 2em;
				text-indent: -2em;
			}
		}
		&__liaison{
			padding-left: 2em;
		}
  }
	.u-p-t-10{
		padding-top: 10px;
	}
}

@include mq-sp {
  .privacypolicy {
    font-size: 13px;
    &__inner {
      width: auto;
      padding: 0 20px;
    }
    &__section{
      margin: 0 0 20px 0;
      &:last-child{
        margin: 0 0 50px 0;
      }
      &__title{
				margin-bottom: 8px;
        &--foreword{
          margin: 0 0 30px 0;
        }
      }
      &__text{
        &--annotation {
//          margin: 0 0 20px 0;
        }
      }
    }
		.u-p-t-10{
			padding-top: 5px;
		}
  }
}