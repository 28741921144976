@charset "utf-8";

.recruit{
  font-size: $base-font-size;
  &__inner{
    width: $inner-width;
    margin: auto;
  }
  &__belief{
    &__message{
      font-size: 28px;
      font-weight: bold;
      line-height: 1.8;
    }
    &__text{
      line-height: 1.8;
      margin: 30px 0 60px 0;
    }
    &__flow{
      display: flex;
      &__box{
        &:not(:first-child){
          margin: 0 0 0 45px;
        }
        width: 290px;
        flex: 1;
        &__img{
          margin: 0 0 10px 0;
        }
        &__caption{
          font-size: 15px;
          line-height: 1.8;
        }
      }
    }
    &__feature{
      &__title{
        font-size: 28px;
        color: $accent-color;
        font-weight: bold;
        margin: 90px 0 40px 0;
      }
      &__list {
        &__item{
          font-size: 26px;
          font-weight: bold;
          &:not(:first-child) {
            margin: 20px 0 0;
          }
        }
      }
      &__text{
        line-height: 1.8;
        margin: 30px 0 150px 0;
      }
      &__num {
        color: $accent-color;
      }
    }  
  }
  &__interview{
    &__no{
      color: $accent-color;
      margin: 0 0 20px 0;
    }
    &__title{
      font-size: 28px;
      font-weight: bold;
      line-height: 1.6;
      margin: 0 0 20px 0;
    }
    &__name{
      margin: 0 0 60px 0;
    }
    &__movie{
      margin: 0 0 60px 0;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 0;
      padding: 56% 0 0;
      iframe {
        position: absolute;
        top: -0.5%;
        left: -0.5%;
        width: 101%;
        height: 101%;
      }
			video {
        position: absolute;
        top: -0.5%;
        left: -0.5%;
        width: 101%;
        height: 101%;
      }
    }
  }
  &__anchor{
    display: block;
    width: 415px;
    padding: 25px 0;
    margin: 110px auto 0;
    background: $accent-color;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  &__anchor-description{
    text-align: center;
    margin: 20px 0 150px 0;
  }
}

@include mq-sp {
  .recruit {
    margin-bottom: 60px;
    &__inner {
      width: auto;
      padding: 0 20px;
    }
    &__belief {
      &__message {
        font-size: 18px;
        line-height: 1.8;
      }
      &__text {
        margin: 12px 0 24px;
        font-size: 14px;
      }
      &__flow {
        display: block;
        &__box {
          width: auto;
          &:not(:first-child) {
            margin: 20px 0 0;
          }
          &__caption {
            font-size: 13px;
          }
        }
      }
      &__feature {
        &__title {
          margin: 30px 0 25px;
          font-size: 21px;
        }
        &__text {
          margin: 20px 0 40px;
          font-size: 14px;
        }
        &__list {
          &__item {
            font-size: 16px;
            line-height: 1.8;
            &:not(:first-child) {
              margin: 8px 0 0;
            }
          }
        }
      }
    }
    &__interview {
      &__no {
        margin: 0 0 12px;
        font-size: 14px;
      }
      &__title {
        margin: 0 0 12px;
        font-size: 18px;
      }
      &__name {
        margin: 0 0 18px;
        font-size: 14px;
      }
      &__movie {
        margin: 0 0 50px;
      }
    }
    &__anchor {
      width: auto;
      margin-top: 80px;
      font-size: 24px;
      &-description {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
}