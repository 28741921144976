@charset "utf-8";

.header {
  position: relative;
  width: 100%;
  padding: 10px 0 0;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 7px;
    background: #4d4d4d;
  }
  &::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: $accent-color;
  }
  &__inner {
    width: $inner-width;
    margin: auto;
  }
  &__caption {
    font-size: 9px;
  }
  &__logo {
    padding: 20px 0;
    &__image {
      width: (942px / 2);
    }
  }
  &__nav {
    position: absolute;
    top: 15px;
    right: 15px;
		//display: none;
    &__list {
      &__item {
        display: inline-block;
        a {
          color: #fff;
        }
        &:not(:first-child) {
          a {
            &::before {
              content: ' | ';
              //color: $base-text-color;
            }
          }
        }
      }
      &__anchor {
        font-size: 13px;
        &.is-current {
          color: $accent-color;
        }
      }
    }
  }
}

@include mq-sp {
  .header {
    height: 160px;
    padding: 0;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
    &__inner {
      width: auto;
    }
    &__caption {
      position: absolute;
      top: 70px;
      left: 8px;
      font-size: 9px;
      line-height: 1.2;
    }
    &__logo {
      position: absolute;
      top: 110px;
      left: 0;
      padding: 0 8px;
      &__image {
        height: auto;
      }
    }
    &__nav {
      position: fixed;
      top: 0;
      right: 0;
      display: block;
      width: 100%;
      padding: 24px 12px;
      background: #13100d;
      text-align: right;
      z-index: 150;
			&__list {
				//display: none;
			}
    }
  }
}