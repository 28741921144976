@charset "utf-8";

.vision{
  font-size: $base-font-size;
  &__inner{
    width: $inner-width;
    margin: auto;
  }
  &__message{
    display: flex;
    margin: 0 0 65px 0;
    &__messagebox{
      flex: 1;
      margin: 0 35px 0 0;
      &__title{
        font-size: 26px;
        font-weight: bold;
        line-height: 1.6;
        margin: 0 0 20px 0;
      }
      &__text{
        line-height: 1.8;
      }
    }
    &__img{
      width: 400px;
    }
  }
  &__overview{
    &__title{
      display: block;
      width: 330px;
      padding: 15px 0;
      margin: 0 0 25px;
      background: #392f2c;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #fff;
    }
    &__table{
      &__detail {
        &__title {
          width:125px;
          padding: 8px 0;
          text-align: left;
          vertical-align: top;
          font-size: 17px;
          white-space: nowrap;
          &--img {
            width:125px;
            padding: 50px 0 8px;
            text-align: left;
            vertical-align: top;
            font-size: 17px;
          }
        }
        &__item {
          padding: 8px 0 8px 54px;
          &--img {
            padding: 50px 0 8px 0;
          }
        }
      }
      &__ith {
        display: inline-block;
        width: 95px;
        line-height: 2;
      }
      &__itd {
        display: inline-block;
        width: 105px;
        text-align: right;
        line-height: 2;
      }
    }
  }
}

@include mq-sp {
  .vision {
    margin-bottom: 60px;
    &__inner {
      width: auto;
      padding: 0 20px;
    }
    &__message {
      display: block;
      margin-bottom: 25px;
      &__messagebox {
        margin: 0;
        font-size: 14px;
        &__title {
          font-size: 21px;
        }
      }
      &__img {
        width: auto;
        margin-top: 20px;
      }
    }
    &__overview {
      &__title {
        width: 70%;
        margin-bottom: 15px;
        padding: 10px;
        font-size: 21px;
      }
      &__table {
        display: block;
        &__detail {
          &__title {
            display: block;
            font-size: 15px;
            &--img {
              display: block;
              padding-top: 10px;
              font-size: 15px;
            }
          }
          &__item {
            display: block;
            padding-left: 0;
            font-size: 13px;
            line-height: 1.8;
            &--img {
              display: block;
              padding-top: 20px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}