@charset "utf-8";

//font
$base-font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, Osaka, "MS PGothic", arial, helvetica, sans-serif;
$base-font-family-mincho: "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$base-font-size: 16px;

//size
$inner-width: 960px;

//color
$base-background-color: #fff;
$base-text-color: #4d4d4d;
$base-anchor-color: #4d4d4d;
$base-anchor-hover-color: #f5911e;
$accent-color: #f5911e;

//breakpoint
$breakpoint: 639px;
