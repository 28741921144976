@charset "utf-8";

html {
  min-width: $inner-width + 300;
}

.hero-image {
  text-align: center;
}

@include mq-sp {
  html {
    min-width: 320px;
  }
}
