@charset "utf-8";

.about-link {
  position: relative;
  margin: 0 0 24px;
  &__title {
    position: absolute;
    top: 0;
    left: 12%;
    &__image {
      width: (280px / 2);
      height: (86px / 2);
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    &__item {
      flex: 1;
    }
    &__anchor {
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 770px;
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
      &__box {
        display: block;
        width: 80%;
        color: #fff;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
      }
      &__title {
        display: block;
        margin: 0 0 12px;
        padding: 14px 10px 12px;
        background: rgba($color: #231815, $alpha: .9);
        font-size: 24px;
      }
      &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        transition: all 0.25s ease-out;
        z-index: -10;
      }
    }
  }
}

@include mq-sp {
  .about-link {
    margin-bottom: 16px;
    &__title {
      left: 5%;
      z-index: 100;
      &__image {
        width: (280px / 3.5);
        height: (86px / 3.5);
      }      
    }
    &__list {
      &__anchor {
        display: block;
        height: 250px;
        &.is-sp {
          &--vision {
            background: url(../img/about-link__list__item--vision.jpg);
            background-size: cover;
          }
          &--location {
            background: url(../img/about-link__list__item--location.jpg);
            background-size: cover;
          }
          &--infographics {
            background: url(../img/about-link__list__item--infographics.jpg);
            background-size: cover;
          }
        }
        &__box {
          margin: auto;
          font-size: 9px;
        }
        &__title {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 64px;
          margin: 85px 0 5px;
          padding: 0 5px;
          font-size: 14px;
          line-height: 1.2;
        }
        &__image {
          display: none;
        }
      }
    }
  }
}