@charset "utf-8";

.disclaimers{
  line-height: 1.8;
  &__inner{
    width: $inner-width;
    margin: auto;
  }
  &__section{
    margin: 0 0 40px 0;
    &:last-child{
      margin: 0 0 100px 0;
    }
    &__title{
      &--foreword{
        margin: 0 0 70px 0;
      }
    }
		&__text{
			text-indent: 1em;
		}
  }
}

@include mq-sp {
  .disclaimers {
    font-size: 14px;
    &__inner{
      width: auto;
      padding: 0 20px;
    }
    &__section{
      margin: 0 0 20px 0;
      &:last-child{
        margin: 0 0 50px 0;
      }
      &__title{
        &--foreword{
          margin: 0 0 35px 0;
        }
      }
    }
  }
}