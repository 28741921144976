@charset "utf-8";

.about {
  position: relative;
  &__list {
    &__item {
      &__inner {
        width: $inner-width;
        margin: auto;
      }
    }
    &__anchor {
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 400px;
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
      &__box {
        display: block;
        width: 325px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
      }
      &__title {
        display: block;
        margin: 0 0 12px;
        padding: 14px 10px 12px;
        background: rgba($color: #231815, $alpha: .9);
        color: #fff;
        font-size: 24px;
        &:hover {
          color: #fff;
        }
      }
      &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        transition: all 0.25s ease-out;
        z-index: -10;
      }
    }
  }
}

@include mq-sp {
  .about {
    &__list {
      &__item {
        &__inner {
          width: auto;
          margin: 0;
          padding-left: 20px;
        }
      }
      &__anchor {
        height: 250px;
        &.is-sp {
          &--vision {
            background: url(../img/about__list__item--vision-sp.jpg);
            background-size: cover;
          }
          &--location {
            background: url(../img/about__list__item--location-sp.jpg);
            background-size: cover;
          }
          &--infographics {
            background: url(../img/about__list__item--infographics-sp.jpg);
            background-size: cover;
          }
        }
        &__box {
          width: 225px;
          font-size: 14px;
        }
        &__title {
          margin-bottom: 8px;
          font-size: 18px;
        }
      }
    }
  }
}