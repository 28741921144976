@charset "utf-8";

.contact {
  &__inner {
    width: $inner-width;
    margin: auto;
  }
  &__read {
    padding: 0 0 140px;
    font-size: 17px;
    line-height: 1.8;
  }
  &__form {
    &__text {
      width: 100%;
      padding: 5px;
      font-size: 16px;
    }
    &__textarea {
      width: 100%;
      padding: 5px;
      font-size: 16px;
    }
    &__radio {
      margin: 0 5px 3px 0;
      vertical-align: middle;
      &:not(:first-child) {
        margin-left: 3em;
      }
    }
  }
  &__error {
    margin: 8px 0 0;
    color: #f00;
    font-size: 14px;
  }
  &__table {
    width: 780px;
    margin: auto;
    &__title {
      text-align: right;
      vertical-align: middle;
      font-weight: normal;
      font-size: 21px;
      &--top {
        padding-top: 40px;
        text-align: right;
        vertical-align: top;
        font-weight: normal;
        font-size: 21px;
      }
    }
    &__detail {
      width: 520px;
      padding: 30px 0 30px 40px;
      font-size: 18px;
      word-break: break-word;
      line-height: 1.2;
    }
    &__notice {
      display: inline-block;
      width: 2.5em;
      color: $accent-color;
      font-weight: bold;
      font-size: 13px;
    }
  }
  &__policy {
    margin: 72px 0;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    &__anchor {
      color: $accent-color;
      text-decoration: underline;
    }
  }
  &__submit {
    display: block;
    width: 600px;
    margin: 45px auto 88px;
    padding: 32px;
    background: $accent-color;
    border: none;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    &:hover {
      color: #fff;
    }
    &--back {
      display: block;
      width: 600px;
      margin: 150px auto 0;
      padding: 32px;
      background: #000;
      border: none;
      border-radius: 20px;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 26px;
    }
  }
}

@include mq-sp {
  .contact {
    margin-bottom: 60px;
    &__inner {
      width: auto;
      padding: 0 20px;
    }
    &__read {
      padding: 0 0 35px;
      font-size: 15px;
      line-height: 1.6;
    }
    &__form {
      &__radio {
        margin-left: 1px;
        &:not(:first-child) {
          margin-left: 1px;
        }
      }
      label {
        display: inline-block;
        padding: 0 0 8px;
      }
    }
    &__table {
      display: block;
      width: auto;
      &__title {
        display: block;
        width: auto;
        padding: 12px 0 6px;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        &--top {
          display: block;
          width: auto;
          padding: 12px 0;
          text-align: left;
          font-weight: bold;
          font-size: 18px;
        }
      }
      &__detail {
        display: block;
        width: auto;
        padding: 8px 0;
        font-size: 15px;
      }
    }
    &__policy {
      margin: 36px 0;
      font-size: 14px;
      line-height: 1.4;
    }
    &__submit {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 0;
      padding: 18px;
      border-radius: 0;
      font-size: 21px;
      &--back {
        width: 100%;
        margin-top: 45px;
        margin-bottom: 0;
        padding: 18px;
        border-radius: 0;
        font-size: 21px;
      }
    }
  }
}