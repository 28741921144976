@charset "utf-8";

.location{
  font-size: $base-font-size;
  &__inner{
    width: $inner-width;
    margin: auto;
  }
  &__area{
    &:not(:first-child){
      padding-top: 50px;
      border-top: 1px #d2d2d2 solid;
    }
    &:not(:last-child){
      margin-bottom: 50px;
    }
    &__box{
      display: flex;
      &__img{
        width: 580px;
        &--nagoya{
          width: 300px;
        }
      }
      &__text{
        flex: 1;
        margin: 0 0 0 30px;
        &__caption{
          padding: 0 0 8px 0;
          font-size: 14px;
        }
        &__title{
          font-size: 30px;
          font-weight: bold;
        }
        &__incname{
          font-size: 18px;
          margin: 10px 0 0 0;
        }
        &__address{
          margin: 25px 0 20px;
          font-size: 14px;
          line-height: 1.5;
          text-align: left;
        }
        &__access{
          display: block;
          width: 160px;
          padding: 5px 0;
          background: #392f2c;
          text-align: center;
          color: #fff;    
        }
      }
    }
    &__siteimage{
      display: flex;
      flex-wrap: wrap;
      &__box{
        margin: 30px 0 0 0;
        &:not(:nth-child(2n-1)){
          margin-left:20px;
        }
        width: 470px;
        &__caption{
          margin: 15px 0 0 0;
          line-height: 1.5;
        }
      }
    }
  }
}

@include mq-sp {
  .location {
    margin-bottom: 60px;
    &__inner {
      width: auto;
      padding: 0 20px;
    }
    &__area {
      &__box {
        display: block;
        &__img {
          width: auto;
        }
        &__text {
          margin: 0;
          &__caption {
            display: none;
          }
          &__title {
            display: none;
          }
          &__incname {
            margin-top: 20px;
          }
          &__address {
            margin-top: 20px;
          }
        }
        &__img {
          &--nagoya {
            width: 50%;
          }
        }
      }
      &__siteimage {
        &__box {
          margin-top: 20px;
          &:not(:nth-child(2n-1)){
            margin-left: 0;
          }
          &__caption {
            margin-top: 8px;
            font-size: 14px;
          }
        }
      }
    }
  }
}