@charset "utf-8";

.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 130px;
  background: #808080;
  color: #fff;
  &__address {
    a {
      color: #fff;
      &:hover {
        color: $accent-color;
      }
    }
    &__name {
      font-size: 26px;
      &__sub {
        display: inline-block;
        margin: 0 0 0 1em;
        vertical-align: middle;
        font-size: 13px;
      }
    }
    &__detail {
      padding: 14px 0;
      font-size: 14px;
      line-height: 1.6;
    }
  }
  &__policy {
    padding: 20px 0;
    font-size: 14px;
  }
  &__nav {
    margin: 10px 0 0;
    &__list {
      display: flex;
      &__item {
        &:not(:first-child) {
          margin-left: 50px;
        }
        &--access {
          margin-left: 0;
        }
      }
      &__anchor {
        display: inline-block;
        margin: 0 0 12px;
        color: #fff;
        font-size: 16px;
        &--sub {
          display: inline-block;
          margin: 0 0 8px;
          color: #fff;
          font-size: 13px;
        }
      }
    }
  }
  &__copyright {
    font-size: 11px;
  }
}

@include mq-sp {
  .footer {
    position: relative;
    display: block;
    padding: 20px 20px 120px;
    &__link {
      display: block;
    }
    &__address {
      &__name {
        font-size: 18px;
        &__sub {
          margin: 4px 0 0;
          font-size: 11px;
          line-height: 1.2;
        }
      }
      &__detail {
        font-size: 9px;
      }
    }
    &__policy {
      font-size: 11px;
    }
    &__nav {
      &__list {
        display: block;
        &__item {
          &:not(:first-child) {
            margin-left: 0;
          }
        }
        &__anchor {
          margin: 15px 0 0;
          font-size: 13px;
          &--sub {
            margin: 12px 0 0;
            font-size: 11px;
          }
        }
      }
    }
    &__copyright {
      font-size: 9px;
      line-height: 1.2;
    }
    &__bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 20px;
    }
  }
}