@charset "utf-8";

* {
  box-sizing: border-box;
}

html {
  font-size: $base-font-size;
}

body,
button,
input,
select,
textarea {
  color: $base-text-color;
  font-family: $base-font-family;
  font-weight: normal;
}

body {
  background: $base-background-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

img {
  max-width: 100%;
  height: auto;
  font-size: 0;
}

img,
svg,
iframe {
  vertical-align: bottom;
}

a {
  color: $base-anchor-color;
  text-decoration: none;
  transition: all 0.25s ease-out;
}

a:hover {
  color: $base-anchor-hover-color;
}

a[href^="tel:"] {
  pointer-events: none;
}

p {
  text-align: justify;
}

ul {
  list-style: none;
}

table {
  width: 100%;
}

.pc-none {
  display: none;
}

@include mq-sp {
  body {
    -webkit-text-size-adjust: 100%;
  }
  a[href^="tel:"] {
    pointer-events: auto;
  }
  input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
  }
  .pc-none {
    display: inline-block;
  }
  .sp-none {
    display: none;
  }
}
